import domReady from '@wordpress/dom-ready';
import $ from 'jquery';
import 'slick-carousel';

domReady(() => {
    console.log('DOM is ready');
    const sliders = $('.aktion-slider');

    if (sliders.length) {
        sliders.slick({
            dots: false,
            infinite: true,
            speed: 800,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,    
            autoplaySpeed: 2000, 
        });
    }
});